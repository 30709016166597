<template>
<div style="display: flex;">
    <CustomerNav @chooseNav="chooseNav" :currentPath="currentPath" />
    <div class="viewbox" v-loading="viewLoading">
        <div class="boxOne">
            <div class="title">我的客户</div>
            <!-- 管理员可以查看整个部门 -->
            <PowerSelect defaultText="仅我的客户" @onChoose="onChoose" />
            

            <div class="rightHandle">
                <el-button @click="newCustomer.visible=true" size="small" type="primary" icon="el-icon-plus" >新建客户</el-button>
                
                <el-button size="small" @click="handleImportClick">
                    <img src="@/assets/icon/upload.png" alt="" class="smallIcon" />导入客户
                </el-button>
                <el-button size="small" @click="downloadVisible = true;" v-if="isExport">
                    客户导出
                </el-button>

                <el-button size="small" @click="openSet">
                    <img src="@/assets/icon/set.png" alt="" class="smallIcon" />设置
                </el-button>
            </div>
        </div>

        <div class="boxTwo">
            <div class="txt">
                <span style="padding-right:10px;">共{{totalItems}}个客户</span>
                <el-tooltip effect="dark" content="刷新" placement="bottom">
                    <i class="el-icon-refresh pointer" @click="onRefresh"></i>
                </el-tooltip>
                <span style="padding:0 10px;"></span>
                <!-- 关注客户筛选 -->
                <el-checkbox v-model="filterParam.isAttention" @change="toSearch">关注客户</el-checkbox>
            </div>
            <div class="rightHandle">
                <el-input v-model="filterParam.companyName" placeholder="公司名称" prefix-icon="el-icon-search" size="small"
                 style="width:215px;margin-right:10px;" @keyup.enter.native="toSearch"></el-input>

                <!-- 私海分组筛选 -->
                <el-select placeholder="私海分组" v-model="filterParam.customerGroupValue" size="small" style="width:130px;margin-right:10px;" @change="toSearch" clearable >
                    <el-option v-for="item in privateGroupConfig" :key="item.value" :label="item.label" :value="item.value" :disabled="item.count==0">
                        {{item.label}}({{item.count}})
                    </el-option>
                </el-select>
                
                <!-- 排序筛选 -->
                <el-select placeholder="排序" v-model="filterParam.sort" size="small" style="width:130px;margin-right:10px;" @change="toSearch">
                    <el-option v-for="item in sortConfig" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>

                <el-button size="small" @click="filterVisible = true;">
                    <img src="@/assets/icon/filter.png" alt="" class="smallIcon" />更多筛选
                </el-button>

                <!-- <el-dropdown>
                    <el-button size="small" style="margin-left:10px;">
                        更多<i class="el-icon-arrow-down el-icon--right"></i>
                    </el-button>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item>更多操作1</el-dropdown-item>
                        <el-dropdown-item>更多操作2</el-dropdown-item>
                        <el-dropdown-item>更多操作3</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown> -->
            </div>
        </div>

        <div class="boxTable" v-loading="tableLoading">
            <el-empty v-if="totalItems==0" description="暂无数据" style="height:calc(100vh - 300px);"></el-empty>
            <el-table v-else ref="customerTable" style="width:100%;cursor: pointer;" height="calc(100vh - 300px)" :data="customerList"
                :header-cell-style="{'background-color':'#F2F2F2','color':'#3A3A3A'}" :row-style="rowStyle"
                @selection-change="selectionChange" @row-click="displayPanel">
                <el-table-column type="selection" width="45" fixed></el-table-column>
                <el-table-column width="40" fixed>
                    <template slot-scope="{row}">
                    <div style="width:100%;min-height:25px" @mouseenter="row.isImageShow=true" @mouseleave="row.isImageShow=false">
                            <img v-show="row.isImageShow||row.isFollow"  @click.stop="handleCFollowClick(row)"  :src="row.isFollow?image.attention:image.noattention" />
                    <!-- <img v-show="row.isImageShow||row.isTop" @click.stop="handleCTopClick(row)" :src="row.isTop?image.top:image.notop"  style="margin-left:10px;" /> -->
                    </div>
                    </template>
                </el-table-column>
                <el-table-column v-for="column of tableColumns" :key="column.property" :label="column.kanji" :width="column.width"
                :fixed="column.kanji==='公司名称' || column.kanji==='国家' || column.isFixed" :show-overflow-tooltip="column.kanji!='公司名称'">
                    <template slot-scope="{row}">
                        <template v-if="column.kanji==='国家'">
                            <div style="display:flex;align-items:center">
                                <img :src="row.flagUrl" style="margin-right:5px;height:14px" />
                                <span style="margin-right:5px;">{{row.country}}</span>
                            </div>
                        </template>
                        <template v-else-if="column.kanji==='公司名称'">
                            <div>
                                <el-popover placement="right" width="300" trigger="hover">
                                    <div style="color:#E6A23C">*撞单风险提示</div>
                                    <el-table :data="row.slightCollisionRisk" stripe size="mini">
                                        <el-table-column width="100" property="userName" label="同事姓名"></el-table-column>
                                        <el-table-column width="200" property="email" label="联系人邮箱"></el-table-column>
                                    </el-table>
                                    <img v-show="isShowCollisionRisk && row.slightCollisionRisk.length>0" class="riskIcon" slot="reference" src="@/assets/icon/risk1.png" alt="" />
                                </el-popover>
                                <el-popover placement="right" width="400" trigger="hover">
                                    <div style="color:#F56C6C;">*撞单提示</div>
                                    <div v-for="(ele,index) in row.seriousCollisionRisk" :key="index">
                                        近30天内,{{ele.userName}}给{{ele.email}}发送过邮件
                                    </div>
                                    <img v-show="isShowCollisionRisk && row.seriousCollisionRisk.length>0" class="riskIcon" slot="reference" src="@/assets/icon/risk2.png" alt="" />
                                </el-popover>
                                <el-popover trigger="hover">
                                    <CompanyCard v-if="row.card1Show" :ckey="row.companyKey" />
                                    <div slot="reference" @mouseenter="hoverCard(row,1)">
                                        <span class="companyName" @click.stop="toDetails(row)">{{row.companyName}}</span>
                                        <CopyIcon :copyValue="row.companyName" />
                                    </div>
                                </el-popover>
                            </div>
                        </template>
                        <template v-else-if="column.kanji==='标签'">
                            <el-tag v-for="item in row.tags" :key="item.key" :color="item.color" type="warning" effect="dark" size="mini">{{item.name}}</el-tag>
                        </template>
                        
                        <template v-else-if="column.kanji==='星级'">
                            <el-rate :value="row[column.property]" disabled />
                        </template>
                        
                        <template v-else-if="column.kanji==='跟进人'">
                            <el-popover trigger="hover">
                                <ColleageCard v-if="row.card2Show" :ckey="row.createUserKey" />
                                <div slot="reference" @mouseenter="hoverCard(row,2)">{{row[column.property]}}</div>    
                            </el-popover>
                        </template>
                        <template v-else-if="column.kanji==='主要联系人'">
                            <el-popover trigger="hover">
                                <ContactCard v-if="row.card3Show" :ckey="row.contactsKey" />
                                <div slot="reference" @mouseenter="hoverCard(row,3)">{{row[column.property]}}</div>    
                            </el-popover>
                        </template>
                        <template v-else>
                            {{row[column.property]}}
                        </template>
                    </template>
                </el-table-column>
            </el-table>
        </div>
 
        <div class="bottomPagination" v-loading="viewLoading">
            <div class="txt" v-show="chooseList.length != 0">已选择{{chooseList.length}}个客户</div>
            <div style="float:left;margin:15px 20px;" v-show="chooseList.length != 0">
                <el-button size="small" @click="biaojiwei.visible=true" :disabled="chooseList.length==0">
                    <img src="@/assets/icon/sign.png" alt="" class="smallIcon" />标记为
                </el-button>
                <el-button size="small" @click="yidongdao.visible=true" :disabled="chooseList.length==0">
                    <img src="@/assets/icon/move.png" alt="" class="smallIcon" />移动到分组
                </el-button>
                <el-button size="small" @click="kehuzhuanyi.visible=true" :disabled="chooseList.length==0">
                    <img src="@/assets/icon/move.png" alt="" class="smallIcon" />转移客户
                </el-button>
                <el-button size="small" @click="releaseToPublic" :disabled="chooseList.length==0">
                    <img src="@/assets/icon/add.png" alt="" class="smallIcon" />释放到公海
                </el-button>
                <el-button size="small" @click="toRecycle" :disabled="chooseList.length==0">
                    加入回收站
                </el-button>
            </div>
            <el-pagination background layout="total,sizes,prev,pager,next" :page-sizes="[10, 20, 40]" :page-size.sync="pageSize" @size-change="pageSizeChange" :total="totalItems" style="float:right;margin:15px 20px;" :current-page.sync="pageIndex" @current-change="pageIndexChange"></el-pagination>
        </div>

        <!-- 客户信息面板 -->
        <CustomerInfoPanel :visible.sync="panelVisible" :customer-key="clickCustomerKey" />

        <!-- 设置列表字段 -->
        <SetField :setVisible="setVisible" :tableColumns="tableColumns" @close="setVisible = false" @set-table-column="handleTableColumn" />

        <!-- 筛选条件 -->
        <el-dialog title="筛选条件" :visible.sync="filterVisible" width="1000px" top="10vh">
            <div class="filterTitle">存档的筛选条件：</div>
            <div class="saveRecordList">
                <div class="saveItem" v-for="item in saveRecordList" :key="item.key">
                    <span @click="clickSaveItem(item)" style="padding-right:10px;">{{item.name}}</span>
                    <i class="el-icon-circle-close" @click="deleteSaveItem(item)"></i>
                </div>
            </div>
            <div class="filterTitle">公司信息：</div>
            <el-form :model="filterParam" label-width="100px" size="small" label-position="left">
                <el-row :gutter="16">
                    <!-- <el-col :span="8">
                        <el-form-item label="关键字">
                            <el-input v-model="filterParam.keyword"></el-input>
                        </el-form-item>
                    </el-col> -->
                   <!--  <el-col :span="8">
                        <el-form-item label="跟进人">
                            <el-input v-model="filterParam.follower"></el-input>
                        </el-form-item>
                    </el-col> -->
                    <el-col :span="8">
                        <el-form-item label="国家/地区">
                            <el-select placeholder="国家/地区" v-model="filterParam.country" size="small" clearable>
                                <el-option v-for="item in countryConfig" :key="item.value" :label="item.label" :value="item.value">
                                    <img :src="item.flagUrl" style="margin-right:5px;height:14px" />
                                    <span>{{item.label}}({{item.count}})</span>
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                   <!--  <el-col :span="8">
                        <el-form-item label="是否关注">
                            <el-checkbox v-model="filterParam.isFocus">已关注</el-checkbox>
                        </el-form-item>
                    </el-col> -->
                    <el-col :span="8">
                        <el-form-item label="公司名称">
                            <el-input v-model="filterParam.companyName"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="主营产品">
                            <el-input v-model="filterParam.mainProduct"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="星级">
                            <el-rate v-model="filterParam.star" style="display: inline-block" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="是否精准搜索">
                            <el-checkbox v-model="filterParam.isPreciseSearch"></el-checkbox>
                        </el-form-item>
                    </el-col>
                    <!-- <el-col :span="8">
                        <el-form-item label="联系人">
                            <el-input v-model="filterParam.contact"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="手机号">
                            <el-input v-model="filterParam.phone"></el-input>
                        </el-form-item>
                    </el-col> -->
                </el-row>
            </el-form>
            <div class="filterTitle">联系人信息：</div>
            <el-form :model="filterParam" label-width="100px" size="small" label-position="left">
                <el-row :gutter="16">
                    <el-col :span="8">
                        <el-form-item label="联系人名称">
                            <el-input v-model="filterParam.contactsName"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="邮箱">
                            <el-input v-model="filterParam.contactsEmail"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="手机号">
                            <el-input v-model="filterParam.contactsCall"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="联系人备注">
                            <el-input v-model="filterParam.contactsNote"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div class="filterTitle">自定义信息：</div>
            <el-form :model="filterParam" label-width="100px" size="small" label-position="left">
                <el-row :gutter="16">
                    <el-col :span="8">
                        <el-form-item label="标签">
                            <el-select v-model="filterParam.tagFilter" placeholder="请选择标签" multiple filterable>
                                <el-option v-for="item in tagOption" :key="item.key" :label="item.name" :value="item.key">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="公司状态">            
                            <el-select placeholder="公司状态" v-model="filterParam.customerStatus" size="small" clearable>
                                <el-option v-for="item in statuConfig" :key="item.value" :label="item.label" :value="item.value" :disabled="item.count==0">
                                    {{item.label}}({{item.count}})
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="私海分组">
                            <el-select v-model="filterParam.customerGroupValue">
                                <!-- <el-option v-for="item in privateGroupOption" :key="item.key" :label="item.name" :value="item.key">
                                </el-option> -->
                                <el-option v-for="item in privateGroupConfig" :key="item.value" :label="item.label" :value="item.value" :disabled="item.count==0">
                                    {{item.label}}({{item.count}})
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="跟进状态">
                            <el-select placeholder="跟进状态" v-model="filterParam.lastFollow" size="small" clearable>
                                <el-option v-for="item in followConfig" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="同事跟过客户">
                            <el-select placeholder="同事跟过客户" v-model="filterParam.companyKeyStr" size="small" clearable>
                                <el-option v-for="item in colleagueConfig" :key="item.value" :label="item.label" :value="item.value">
                                    {{item.label}}({{item.count}})
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="公司来源">
                            <el-select placeholder="公司来源" v-model="filterParam.sourceKey" size="small" clearable>
                                <el-option v-for="item in sourceConfig" :key="item.value" :label="item.label" :value="item.value" :disabled="item.count==0">
                                    {{item.label}}({{item.count}})
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                  <!--   <el-col :span="8">
                        <el-form-item label="创建时间">
                            <el-date-picker v-model="filterParam.createTime" type="date" value-format="yyyy-MM-dd" placeholder="选择日期" style="width:100%;"></el-date-picker>
                        </el-form-item>
                    </el-col> -->
                    <!-- <el-col :span="8">
                        <el-form-item label="来源详情">
                            <el-input v-model="filterParam.sourceDetails"></el-input>
                        </el-form-item>
                    </el-col> -->
                   <!--  <el-col :span="8">
                        <el-form-item label="公司来源">
                            <el-select v-model="filterParam.customerSource" placeholder="请选择公司来源">
                                <el-option v-for="item in customerSourceOption" :key="item.key" :label="item.name" :value="item.key" />
                            </el-select>
                        </el-form-item>
                    </el-col> -->
                  <!--   <el-col :span="8">
                        <el-form-item label="创建人">
                            <el-input v-model="filterParam.creator"></el-input>
                        </el-form-item>
                    </el-col> -->
                </el-row>
            </el-form>
           <!--  <div class="filterTitle">跟进进度：</div>
            <el-form :model="filterParam" label-width="100px" size="small" label-position="left">
                <el-row :gutter="16">
                    <el-col :span="8">
                        <el-form-item label="跟进情况">
                            <el-checkbox-group v-model="filterParam.followCase" style="height:32px;">
                                <el-checkbox label="一人跟进"></el-checkbox>
                                <el-checkbox label="两个及以上"></el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="最近跟进时间">
                            <el-date-picker v-model="filterParam.recentFollowTime" type="date" value-format="yyyy-MM-dd" placeholder="选择日期" style="width:100%;"></el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="下次跟进时间">
                            <el-date-picker v-model="filterParam.nextFollowTime" type="date" value-format="yyyy-MM-dd" placeholder="选择日期" style="width:100%;"></el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="最近进入私海">
                            <el-input v-model="filterParam.keyword"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="最近成交日期">
                            <el-date-picker v-model="filterParam.fixtureDate" type="date" value-format="yyyy-MM-dd" placeholder="选择日期" style="width:100%;"></el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="资料更新时间">
                            <el-date-picker v-model="filterParam.updateTime" type="date" value-format="yyyy-MM-dd" placeholder="选择日期" style="width:100%;"></el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="进入公海次数">
                            <el-input v-model="filterParam.keyword"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="最近进入公海">
                            <el-input v-model="filterParam.keyword"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="即将移入公海">
                            <el-input v-model="filterParam.keyword"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="最近修改人">
                            <el-input v-model="filterParam.modifier"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="年采购额">
                            <el-input v-model="filterParam.annualProcurementVolume"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form> -->
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="saveVisible = true">保存筛选条件</el-button>
                <el-button size="small" type="primary" @click="onFilter">确认筛选</el-button>
                <!-- <el-button size="small" @click="filterVisible = false">取 消</el-button> -->
            </span>
        </el-dialog>

        <!-- 保存筛选条件 -->
        <el-dialog title="保存筛选条件" :visible.sync="saveVisible" width="500px">
            <el-input v-model="saveName" placeholder="命名"></el-input>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" type="primary" :loading="btnLoading" :disabled="!saveName" @click="onSaveFilterCondition">保 存</el-button>
            </span>
        </el-dialog>

        <el-dialog title="新建客户" :visible.sync="newCustomer.visible" width="1000px" top="10vh" v-loading="newCustomer.loading">
            <div class="newCustomerTtiel">公司信息字段：</div>
            <el-form label-width="100px" size="small" label-position="left">
                <el-row :gutter="16">
                    <el-col :span="8">
                        <el-form-item label="公司名称" required>
                            <el-input v-model="newCustomer.form.name" @blur="checkRepeat" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="公司分组" :required="this.isRequired.group">
                            <el-select v-model="newCustomer.form.privateGroupKey">
                                <el-option v-for="item in privateGroupOption" :key="item.key" :label="item.name" :value="item.key" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="公司来源" :required="this.isRequired.source">
                            <el-select v-model="newCustomer.form.customerSourceKey">
                                <el-option v-for="item in customerSourceOption" :key="item.key" :label="item.name" :value="item.key" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="规模" :required="this.isRequired.scale">
                            <el-select v-model="newCustomer.form.companyScaleValue">
                                <el-option v-for="item of companyScaleValueOptions" :key="item.value" :label="item.name" :value="item.name" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="国家地区" :required="this.isRequired.country">
                            <el-input  v-model="newCustomer.form.country" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="公司状态" :required="this.isRequired.statu">
                            <el-select v-model="newCustomer.form.statuKey" placeholder="公司状态">
                                <el-option v-for="item in statusOption" :key="item.key" :label="item.name" :value="item.key"/>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="公司简称" :required="this.isRequired.nickName">
                            <el-input v-model="newCustomer.form.nickName" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="主营产品" :required="this.isRequired.mainIndustryValue">
                            <el-input v-model="newCustomer.form.mainIndustryValue" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="公司类型" :required="this.isRequired.mainBusinessTypeValue">
                            <el-input v-model="newCustomer.form.mainBusinessTypeValue" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="公司地址" :required="this.isRequired.address">
                            <el-input v-model="newCustomer.form.address" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="网址" :required="this.isRequired.webSite">
                            <el-input v-model="newCustomer.form.webSite" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="传真" :required="this.isRequired.fax">
                            <el-input v-model="newCustomer.form.fax" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="电话" :required="this.isRequired.call">
                            <el-input v-model="newCustomer.form.call"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="公司备注" :required="this.isRequired.note">
                            <el-input v-model="newCustomer.form.remarks" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="客户星级" :required="this.isRequired.star">
                            <el-rate v-model="newCustomer.form.star" />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div class="newCustomerTtiel">联系人信息字段：</div>
            <el-form label-width="100px" size="small" label-position="left">
                <el-row :gutter="16">
                    <el-col :span="8">
                        <el-form-item label="主要联系人">
                            <el-radio-group v-model="newCustomer.presetFormContact.isMain">
                                <el-radio :label="true">是</el-radio>
                                <el-radio :label="false">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="联系人名称" required>
                            <el-input v-model="newCustomer.presetFormContact.nickname" placeholder="联系人名称"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="邮箱" required>
                            <el-input v-model="newCustomer.presetFormContact.email" placeholder="邮箱"></el-input>
                        </el-form-item>
                    </el-col>
                    <!-- <el-col :span="8">
                        <el-form-item label="职级">
                            <el-input v-model="newCustomer.presetFormContact.workRank" placeholder="职级"></el-input>
                        </el-form-item>
                    </el-col> -->
                    <el-col :span="8">
                    <el-form-item label="职位">
                        <el-input v-model="newCustomer.presetFormContact.position" placeholder="职位"></el-input>
                    </el-form-item>
                    </el-col>
                    <el-col :span="8">
                    <el-form-item label="联系电话">
                        <el-input v-model="newCustomer.presetFormContact.call" placeholder="联系电话"></el-input>
                    </el-form-item>
                    </el-col>
                    <!-- <el-col :span="8">
                        <el-form-item label="linkedin">
                            <el-input v-model="newCustomer.presetFormContact.linkedin" placeholder="linkedin"></el-input>
                        </el-form-item>
                    </el-col> -->
                    <!-- <el-col :span="8">
                        <el-form-item label="twitter">
                            <el-input v-model="newCustomer.presetFormContact.twitter" placeholder="twitter"></el-input>
                        </el-form-item>
                    </el-col> -->
                    <el-col :span="8">
                        <el-form-item label="性别">
                            <el-input v-model="newCustomer.presetFormContact.gender" placeholder="性别"></el-input>
                        </el-form-item>
                    </el-col>
                    <!-- <el-col :span="8">
                        <el-form-item label="生日">
                            <el-date-picker v-model="newCustomer.presetFormContact.birthday" type="date" placeholder="生日" value-format="yyyy-MM-dd" />
                        </el-form-item>
                    </el-col> -->
                    <el-col :span="8">
                        <el-form-item label="备注">
                            <el-input v-model="newCustomer.presetFormContact.note"  placeholder="备注" ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <!-- 添加自定义字段 -->
            <ShowSaveField ref="saveField" :type="0" />
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="handleNewConfirmClick(0)">加入公海客户</el-button>
                <el-button size="small" type="primary" @click="handleNewConfirmClick(1)">加入我的客户</el-button>
                <!-- <el-button size="small" @click="newCustomer.visible = false">取 消</el-button> -->
            </span>
        </el-dialog>
        <el-dialog title="标记客户" :visible.sync="biaojiwei.visible" width="500px" top="10vh" v-loading="biaojiwei.loading">
            <el-form size="small">
                <el-form-item label="客户：">
                    <span>{{chooseList.length}}个客户</span>
                </el-form-item>
                <el-form-item label="标记为：">
                    <el-select v-model="biaojiwei.form.value" placeholder="全部标签">
                        <el-option v-for="item of biaojiwei.form.options" :key="item.key" :value="item.key" :label="item.name" />
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" type="primary" @click="handleTagConfirmClick">确定</el-button>
                <el-button size="small" @click="biaojiwei.visible = false">取 消</el-button>
            </span>
        </el-dialog>
        <el-dialog title="公司分组" :visible.sync="yidongdao.visible" width="500px" top="10vh" v-loading="yidongdao.loading">
            <el-form size="small">
                <el-form-item label="客户：">
                    <span>{{chooseList.length}}个客户</span>
                </el-form-item>
                <el-form-item label="标记为：">
                    <el-select v-model="yidongdao.form.value" placeholder="全部分组">
                       <el-option v-for="item in privateGroupOption" :key="item.key" :label="item.name" :value="item.key" />
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" type="primary" @click="handleTransferConfirmClick" >确定</el-button>
                <el-button size="small" @click="yidongdao.visible = false">取 消</el-button>
            </span>
        </el-dialog>
        <el-dialog title="客户转移" :visible.sync="kehuzhuanyi.visible" width="500px" top="10vh" v-loading="kehuzhuanyi.loading">
            <el-form size="small">
                <el-form-item label="转移到：">
                    <el-select v-model="kehuzhuanyi.value" filterable>
                        <el-option v-for="item of kehuzhuanyi.options" :key="item.uKey" :value="item.uKey" :label="item.userCnName" />
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="kehuzhuanyi.visible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="handleKehuZhuanyiConfirm">确定</el-button>
            </span>
        </el-dialog>

        <!-- 客户导出 -->
        <el-dialog title="客户导出" :visible.sync="downloadVisible" width="500px">
            <el-radio-group v-model="downloadType" size="small">
                <el-radio :label="0" border>以公司维度导出</el-radio>
                <el-radio :label="1" border>以联系人维度导出</el-radio>
            </el-radio-group>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" type="primary" @click="exportCustomers" :loading="downloadLoading">确定</el-button>
                <el-button size="small" @click="downloadVisible = false">取 消</el-button>
            </span>
        </el-dialog>

        <!-- 加入回收站 -->
        <el-dialog title="加入回收站" :visible.sync="recycleVisible" width="500px">
            <el-input type="textarea" :rows="2" placeholder="请输入原因" v-model="recycleReason"></el-input>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" type="primary" :disabled="!recycleReason" @click="confirmRecycle">确 定</el-button>
                <el-button size="small" @click="recycleVisible = false">取 消</el-button>
            </span>
        </el-dialog>


        <el-dialog title="客户查重" :visible.sync="repeatVisible" width="500px">
            <p style="color: var(--themeColor);">*tip 该公司名称可能与以下公司重复</p>
            <el-table :data="repeatCompanyList" style="width: 100%">
                <el-table-column prop="companyName" label="公司名称"></el-table-column>
                <el-table-column prop="belongTo" label="所属成员"></el-table-column>
            </el-table>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="repeatVisible = false;">关 闭</el-button>
            </span>
        </el-dialog>
        
    </div>
</div>
</template>

<script>
import CustomerNav from "@/components/CustomerNav.vue";
import CustomerInfoPanel from "@/components/CustomerInfoPanel.vue"; //客户信息面板
import SetField from "@/components/SetField.vue";                   //设置列表字段
import { Message } from 'element-ui';

const image={
    noattention:require('@/assets/icon/noattention.png'),
    attention:require('@/assets/icon/attention.png'),
    notop:require('@/assets/icon/notop.png'),
    top:require('@/assets/icon/top.png')
}
import CompanyCard from "@/components/CompanyCard.vue"; //公司卡片
import ContactCard from "@/components/ContactCard.vue"; //联系人卡片
import ColleageCard from "@/components/ColleageCard.vue"; //同事卡片
import PowerSelect from "@/components/PowerSelect.vue";

import ShowSaveField from "@/components/ShowSaveField.vue";

export default {
    components: { CustomerNav, CustomerInfoPanel , SetField , CompanyCard , ContactCard , ColleageCard, PowerSelect, ShowSaveField},
    props: {
        handleSelect: {
            type: Function,
            default: null,
        },
    },
    data() {
        return {
            organizationKey: "",
            salekey: "",
            currentPath: "/customerManage/myCustomer",
            btnLoading: false,
            //保存筛选条件
            saveVisible: false,
            saveName: "",
            saveRecordList: [],
            //加入回收站
            recycleVisible: false,
            recycleReason: "",
            //客户导出
            downloadVisible: false,
            downloadLoading: false,
            downloadType: 0,    // 0 以公司维度导出 1 以联系人维度导出
            image,
            viewLoading: false,

            tableColumns:[],

            biaojiwei:{
                loading:false,
                visible:false,
                form:{
                    value:'',
                    options:[]
                }  
            },
            yidongdao:{
                loading:false,
                visible:false,
                form:{
                    value:'',
                    options:[]
                }  
            },
            kehuzhuanyi: {
                visible: false,
                loading: false,
                value: "",
                options: [],
            },
            newCustomer:{
                loading:false,
                visible:false,
                presetFormContact:{
                    "key": "",
                    "companyKey": "",
                    "isMain": true,
                    "nickname": "",
                    "email": "",
                    "workRank": "",
                    "position": "",
                    "call": "",
                    "linkedin": "",
                    "twitter": "",
                    "gender": "",
                    "birthday": "",
                    "note": "",
                    "images": [ ]
                }, //需要转换，不可直接传输的表单
                form:{
                   "companyKey": "",
                    "companyNo": "",
                    "name": "",
                    "nickname": "",
                    "customerSourceKey": "",
                    "mainIndustryValue": "",
                    "mainBusinessTypeValue": "",
                    "companyScaleValue": "少于59人",
                    "country": "",
                    "timeZone": "",
                    "star": 0,
                    "webSite": "",
                    "fax": "",
                    "areaCodeValue": "",
                    "call": "",
                    "address": "",
                    "contacters": [],
                    "privateGroupKey": "",
                    "publicGroupKey": "",
                    "statuKey": "",
                    "tags": [],
                    "note": ""
                },
            },
            // 过滤字段
            filterParam:{
                companyName: "",
                customerGroupValue: "",
                //更多筛选条件
                keyword: "",
                follower: "",
                isFocus: false,
                mainProduct: "",
                star: 0,
                contact: "",
                phone: "",
                customerTag: "",
                customerStatus: "",
                customerType: "",
                createTime: "",
                sourceDetails: "",
                customerSource: "",
                creator: "",
                followCase: [],
                recentFollowTime: "",
                nextFollowTime: "",
                fixtureDate: "",
                updateTime: "",
                modifier: "",
                annualProcurementVolume: "",
                //新增的下拉框筛选
                lastFollow: "",
                companyKeyStr: "",
                sourceKey: "",
                sort: "0",
                country: "",
                //新增(关注客户/标签)筛选
                isAttention: false,
                tagFilter: [],
                //新增(联系人信息)筛选
                contactsName: "",
                contactsEmail: "",
                contactsCall: "",
                contactsNote: "",
                //是否精准搜索
                isPreciseSearch: false,
            },
            privateGroupOption:[],
            customerSourceOption:[],
            statusOption:[],
            tagOption: [],

            tableLoading: false,
            customerList: [],
            totalItems: 0,
            pageIndex: 1,
            pageSize: 10,

            
            //勾选的客户列表
            chooseList: [],

            //客户信息面板
            panelVisible: false,
            clickCustomerKey: "",
            customerInfo: {},
        
            //设置列表字段
            setVisible: false,
            //筛选条件弹框
            filterVisible: false,

            companyScaleValueOptions: [
                {
                    value: 1,
                    name: "少于59人",
                    en_name: "Less than 59 employees",
                },
                {
                    value: 2,
                    name: "60-149人",
                    en_name: "60-149 employees",
                },
                {
                    value: 3,
                    name: "150-499人",
                    en_name: "150-499 employees",
                },
                {
                    value: 4,
                    name: "500-999人",
                    en_name: "500-999 employees",
                },
                {
                    value: 5,
                    name: "1000-4999人",
                    en_name: "1000-4999 employees",
                },
                {
                    value: 6,
                    name: "5000人以上",
                    en_name: "More than 5000 employees",
                },
            ],

            //新增部分下拉框过滤筛选功能（带有数字提示）
            colleagueConfig: [],
            countryConfig: [],
            sourceConfig: [],
            followConfig: [],
            sortConfig: [],
            statuConfig: [],
            privateGroupConfig: [],

            isShowCollisionRisk: localStorage.sysConfig ? JSON.parse(localStorage.sysConfig).isShowCollisionRisk : false,

            isExport: localStorage.userInfo ? JSON.parse(localStorage.userInfo).isExport : false,   //客户导出权限
            //公司系统配置
            comConfig: {},
            isRequired: {},
            //重复公司名列表
            repeatCompanyList: [],
            repeatVisible: false,
        };
    },
    methods: {
        chooseNav(index){
            this.handleSelect(index);
            this.$router.push(index);
        },

        //客户查重(检测公司名称是否重复提示)
        checkRepeat(){
            let companyName = this.newCustomer.form.name;
            if(!companyName){return;}
            this.$axios.get(`${this.$customerUrl}/Company/CustomerCheck?companyName=${companyName}`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess && data.length) {
                    this.repeatVisible = true;
                    this.repeatCompanyList = data;
                }
            });
        },

        onRefresh(){
            //初始化字段
            this.filterParam = {
                companyName: "",
                customerGroupValue: "",
                //更多筛选条件
                keyword: "",
                follower: "",
                isFocus: false,
                mainProduct: "",
                star: 0,
                contact: "",
                phone: "",
                customerTag: "",
                customerStatus: "",
                customerType: "",
                createTime: "",
                sourceDetails: "",
                customerSource: "",
                creator: "",
                followCase: [],
                recentFollowTime: "",
                nextFollowTime: "",
                fixtureDate: "",
                updateTime: "",
                modifier: "",
                annualProcurementVolume: "",
                //新增的下拉框筛选
                lastFollow: "",
                companyKeyStr: "",
                sourceKey: "",
                sort: "0",
                country: "",
                //新增(关注客户/标签)筛选
                isAttention: false,
                tagFilter: [],
                //新增(联系人信息)筛选
                contactsName: "",
                contactsEmail: "",
                contactsCall: "",
                contactsNote: "",
                //是否精准搜索
                isPreciseSearch: false,
            };
            this.toSearch();
        },
        rowStyle({row,rowIndex}){
            // 选中客户行背景色变换
            if (row.key == this.clickCustomerKey) {
                return {'background-color':'#E3EAEF'}
            }
            // 表格隔行换色
            if(rowIndex % 2 == 1){
                return {'background-color':'#F9FAFC'}
            }
        },

        hoverCard(row,type){
            if(type == 1){ row.card1Show = true;}
            if(type == 2){ row.card2Show = true;}
            if(type == 3){ row.card3Show = true;}
        },

        toSearch(){
            this.pageIndex = 1;
            this.getCustomerList();
        },

        getCustomerList(){
            this.tableLoading = true;
            let submitObj = {
                privateOrPublic: 'private',
                "privateGroupKey": this.filterParam.customerGroupValue,
                "statuKey": this.filterParam.customerStatus,
                "name": this.filterParam.companyName,
                "mainIndustryValue": this.filterParam.mainProduct,
                "mainBusinessTypeValue": this.filterParam.customerType,
                "companyScaleValue": "",
                "star": this.filterParam.star,
                "contact": this.filterParam.contact,
                "phone": this.filterParam.phone,
                "pageIndex": this.pageIndex,
                "pageSize": this.pageSize,
                organizationKey: this.organizationKey,
                ukey: this.salekey,
                //新增的下拉框筛选
                lastFollow: this.filterParam.lastFollow,
                companyKeyStr: this.filterParam.companyKeyStr,
                sourceKey: this.filterParam.sourceKey,
                sort: this.filterParam.sort,
                country: this.filterParam.country,
                //新增(关注客户/标签)筛选
                isAttention: this.filterParam.isAttention,
                tagFilter: this.filterParam.tagFilter,
                //新增(联系人信息)筛选
                contactsName: this.filterParam.contactsName,
                contactsEmail: this.filterParam.contactsEmail,
                contactsCall: this.filterParam.contactsCall,
                contactsNote: this.filterParam.contactsNote,
                //是否精准搜索
                isPreciseSearch: this.filterParam.isPreciseSearch,
            }
            this.$axios.post(`${this.$customerUrl}/Company/Private`,submitObj).then((res) => {
                const { isSuccess, data } = res;
                this.tableLoading = false;
                if (isSuccess) {
                    this.customerList = data.datas.map(item=>({
                        ...item,
                        flagUrl:window.getFlagUrlByCountry?window.getFlagUrlByCountry(item.country):'',
                        isImageShow:false,
                        card1Show: false,
                        card2Show: false,
                        card3Show: false,
                    }))
                    // this.customerList.forEach(item => {
                    //     // 轻度撞单风险客户
                    //     // console.log(item.slightCollisionRisk);
                    //     // 严重撞单风险客户
                    //     // console.log(item.seriousCollisionRisk);
                    // })
                    this.totalItems = data.totalItems;
                    this.$nextTick(() =>{
                        this.$refs.customerTable.doLayout();
                    })
                }
            });

        },
        pageIndexChange(page) {
            this.pageIndex = page;
            this.getCustomerList();
        },
        pageSizeChange() {
            this.pageIndex = 1;
            this.getCustomerList();
        },
        //前往客户详情
        toDetails(row){
            // this.handleSelect(`/customerManage/customerDetailsPrivate?customerKey=${row.companyKey}`);
            // this.$router.push(`/customerManage/customerDetailsPrivate?customerKey=${row.companyKey}`);
            let routeData = this.$router.resolve({
                path: "/customerManage/customerDetailsPrivate",
                query: { customerKey: row.companyKey },
            });
            window.open(routeData.href, "_blank");
        },

        //前往批量导入
        handleImportClick(){
            this.handleSelect(`/customerManage/import`);
            this.$router.push(`/customerManage/import`);
        },

        //客户导出
        exportCustomers(){
            this.downloadLoading = true; 
            this.$axios({
                url: `${this.$customerUrl}/Company/ExportCompany?type=${this.downloadType}`,
                method: 'get',
                responseType: 'blob',
            }).then(response => {
                this.downloadLoading = false;
                this.downloadVisible = false;
                var blob = new Blob([response]);
                var link= document.createElement('a');
                link.download = '我的客户.xlsx';
                link.href = URL.createObjectURL(blob);
                link.click();
                this.$message({message: "客户导出成功",type: 'success'});
            });
        },

        //勾选的客户列表
        selectionChange(value){
            //console.log(value);
            this.chooseList = value;
        },
        //打开右侧客户信息面板
        displayPanel(item){
            //console.log(item);
            this.clickCustomerKey = item.companyKey;
            this.panelVisible = true;
            this.customerInfo = item;
        },
        closePanel(){
            this.clickCustomerKey = "";
            this.panelVisible = false;
        },

        //自定义设置
        openSet(){
            this.setVisible = true;
        },

        //更多筛选提交
        onFilter(){
            this.filterVisible = false;
            this.pageIndex = 1;
            this.getCustomerList();
        },

        //保存筛选条件
        onSaveFilterCondition(){
            this.btnLoading = true;
            let parameter = JSON.stringify(this.filterParam);
            this.$axios.post(`${this.$customerUrl}/Company/SaveSearchRecord`,{
                name: this.saveName,
                parameter: parameter,
                type: "private",
            }).then((res) => {
                const { isSuccess } = res;
                this.btnLoading = false;
                this.saveVisible = false;
                if (isSuccess) {
                    this.$message({ message: '保存成功', type: 'success'});
                    this.onFilter();
                    this.getSearchRecord();
                }
            });
        },
        //获取存档的筛选条件
        getSearchRecord(){
            this.$axios.post(`${this.$customerUrl}/Company/GetSearchRecord`,{
                type: "private",pageIndex: 1,pageSize: 10,
            }).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.saveRecordList = data.datas;
                }
            });
        },
        //点击存档搜索
        clickSaveItem(item){
            this.filterParam = JSON.parse(item.parameter);
            this.onFilter();
        },
        //删除存档
        deleteSaveItem(item){
            this.$axios.get(`${this.$customerUrl}/Company/DeleteSearchRecord?key=${item.key}`).then((res) => {
                const { isSuccess } = res;
                if (isSuccess) {
                    this.getSearchRecord();
                }
            });
        },

        //释放到公海
        releaseToPublic(){
            if(!this.chooseList.length)return
            const companyKeys=this.chooseList.map(item=>item.companyKey)
            this.viewLoading=true
            Promise.allSettled(companyKeys.map(item=>this.$axios({
                method:'GET',
                url:this.$customerUrl+'/Company/RemovePrivate',
                params:{
                    companykey:item
                }
            }))).finally(()=>{
                this.viewLoading=false
                this.$message({ message: '释放成功', type: 'success'});
                this.getCustomerList()
            })
        },
        //加入回收站
        toRecycle(){
            if(!this.chooseList.length)return
            this.recycleVisible = true;
            this.recycleReason = "";
        },
        confirmRecycle(){
            const companyKeys = this.chooseList.map(item=>item.companyKey);
            this.$axios.post(`${this.$customerUrl}/Company/DeleteCustomers`,{
                customerKey: companyKeys,
                reason: this.recycleReason,
            }).then((res) => {
                const { isSuccess } = res;
                this.recycleVisible = false;
                if (isSuccess) {
                    this.$message({ message: '回收成功', type: 'success'});
                    this.getCustomerList();
                }
            });
        },

        async getTableColumnWhenMounted(){
            try {
                const res = await this.$axios({
                method:'GET',
                url: this.$customerUrl+ '/v1/Config/GetApiCustomParameter',
                params:{
                    apiurl:'Company/Private'
                }
            })
            if(!res.isSuccess||res.data.length===0){
                throw new Error()
            }
            // const data=res.data
            const data = res.data.filter(item => item.lable !== "客户编号");
            this.tableColumns=data.map(item=>({
                kanji:item.lable,
                property:item.key,
                width:item.width,
                isFixed: item.isFixed,
            }))
            } catch  {
                this.tableColumns=[{
                kanji:'公司名称',
                property:'companyName'
            },{
                  kanji:'标签',
                property:'tags'
            },{
                kanji:'国家',
                property:'country'
            },{
                kanji:'公司来源',
                property:'source'
            }]
            }
        },
        async handleTableColumn(columns){
            try {
                this.tableColumns=columns
                this.setVisible=false
                await  this.$axios({
                    method:'POST',
                    url:this.$customerUrl+'/v1/Config/CustomParameter',
                    data:columns.map(item=>({
                        apiurl: 'Company/Private',
                        key: item.property,
                        lable: item.kanji,
                        isFixed: item.isFixed,
                        //width: item.width||''
                    }))
                })
            } catch  {
               null
            }
        },
        async getGroupList() {
            try {
                const res = await this.$axios({
                    method: "GET",
                    url: this.$customerUrl + "/v1/Config/PrivateGroup",
                });
                this.privateGroupOption = res.data || [];
                this.newCustomer.form.privateGroupKey = res.data.filter(ele=>ele.isDefault)[0]?.key??"";
            } catch {
                this.privateGroupOption = [];
            }
        },
        async getSourceOption(){
            try {
                const res=await this.$axios({
                    method: "GET",
                    url: this.$customerUrl + "/v1/Config/Source",
                });
                this.customerSourceOption = res.data || [];
                this.newCustomer.form.customerSourceKey = res.data.filter(ele=>ele.isDefault)[0]?.key??"";
            } catch  {
                this.customerSourceOption = [];
            }
        },
        async getStatusOption(){
            try {
                const res=await this.$axios({
                    method: "GET",
                    url: this.$customerUrl + "/v1/Config/Statu",
                });
                this.statusOption = res.data || [];
                this.newCustomer.form.statuKey = res.data.filter(ele=>ele.isDefault)[0]?.key??"";
            } catch  {
                this.statusOption = [];
            }
        },
        validateNotEmpty(str,label,isRequired){
            if(isRequired && str.trim()===''){
                Message.warning(`${label}不得为空`)
                throw new Error()
            }
        },
        async handleNewConfirmClick(type){
            try {
                const arr = [
                    [this.newCustomer.form.name,'公司名称',true],
                    [this.newCustomer.form.privateGroupKey,'公司分组',this.isRequired.group],
                    [this.newCustomer.form.customerSourceKey,'公司来源',this.isRequired.source],
                    [this.newCustomer.form.companyScaleValue,'规模',this.isRequired.scale],
                    [this.newCustomer.form.country,'国家地区',this.isRequired.country],
                    [this.newCustomer.form.statuKey,'公司状态',this.isRequired.statu],
                    [this.newCustomer.form.nickName,'公司简称',this.isRequired.nickName],
                    [this.newCustomer.form.mainIndustryValue,'主营产品',this.isRequired.mainIndustryValue],
                    [this.newCustomer.form.mainBusinessTypeValue,'公司类型',this.isRequired.mainBusinessTypeValue],
                    [this.newCustomer.form.address,'公司地址',this.isRequired.address],
                    [this.newCustomer.form.webSite,'网址',this.isRequired.webSite],
                    [this.newCustomer.form.fax,'传真',this.isRequired.fax],
                    [this.newCustomer.form.call,'电话',this.isRequired.call],
                    [this.newCustomer.form.remarks,'公司备注',this.isRequired.note],
                    [this.newCustomer.form.star,'星级',this.isRequired.star],
                    [this.newCustomer.presetFormContact.nickname,'联系人名称',true],
                    [this.newCustomer.presetFormContact.email,'邮箱',true]
                ]
                for(const [str,label,isRequired] of arr){
                    this.validateNotEmpty(str,label,isRequired);
                }
            } catch {
                return
            }
            try {
                this.newCustomer.loading = true;
                await  this.$axios({
                    method:'POST',
                    url:this.$customerUrl+'/Company/Save',
                    data:{
                        ...this.newCustomer.form,
                        contacters:[this.newCustomer.presetFormContact],
                        type: type, //0加入公海客户 1加入我的客户
                    }
                }).then(res => {
                    if(res.data){
                        this.$refs.saveField.onSave(res.data);
                    }
                })
            } catch {
                null
            } finally {
                this.newCustomer.visible = false;
                this.newCustomer.loading = false;
                this.getCustomerList();
            }
        },
        async handleTransferConfirmClick(){
            if(!this.chooseList.length||!this.yidongdao.form.value){ return false;}
            try {
                this.yidongdao.loading = true;
                const res = await this.$axios({
                    method:'POST',
                    url:this.$customerUrl+'/Company/CustomerGroup',
                    data: {
                        companyKey: this.chooseList.map(item=>item.companyKey),
                        groupKey: this.yidongdao.form.value
                    }
                })
                if(!res.isSuccess){
                    throw new Error()
                }
                this.yidongdao.visible = false;
                this.getCustomerList();
            } catch {
                null
            } finally {
                this.yidongdao.loading = false;
            }
        },
        async handleTagConfirmClick(){
            if(!this.chooseList.length||!this.biaojiwei.form.value){ return false; }
            try {
                this.biaojiwei.loading = true;
                const res = await this.$axios({
                    method:'POST',
                    url:this.$customerUrl+'/Company/BatchAddTag',
                    data: {
                        companyKey: this.chooseList.map(item=>item.companyKey),
                        tagKey: [this.biaojiwei.form.value]
                    }
                })
                if(!res.isSuccess){
                    throw new Error()
                }
                this.biaojiwei.visible = false;
                this.getCustomerList();
            } catch {
                null
            } finally {
                this.biaojiwei.loading = false;
            }
        },
        async getOptions() {
            try {
                const res = await this.$axios({
                method: "GET",
                url: this.$customerUrl + "/v1/Config/Tag",
                });
                this.biaojiwei.form.options = res.data;
            } catch {
                this.biaojiwei.form.options=[]
            }
        },
        async handleCTopClick(row){
            try {
                this.tableLoading = true;
                const res=await this.$axios({
                    method:'GET',
                    url:this.$customerUrl +'/Company/Top',
                    params:{
                        customerKey:row.companyKey,
                        type:row.isTop?0:1
                    }
                })
                if(res.isSuccess){
                    this.getCustomerList();
                }
            } catch  {
                null
            }finally{
                this.tableLoading = false;
            }
        },
        async handleCFollowClick(row){
            try {
                this.tableLoading = true;
                const res=await this.$axios({
                    method:'GET',
                    url:this.$customerUrl +'/Company/Follow',
                    params:{
                        customerKey:row.companyKey,
                        type:row.isFollow?0:1
                    }
                })
                if(res.isSuccess){
                    this.getCustomerList();
                }
            } catch  {
                null
            }finally{
                this.tableLoading = false;
            }
        },

        async handleKehuZhuanyiConfirm() {
            if (!this.kehuzhuanyi.value) {
                Message.warning("请选择同事");
                return;
            }
            try {
                this.kehuzhuanyi.loading = true;
                const res = await this.$axios({
                    method: "POST",
                    url: this.$customerUrl + "/Company/v2/Transfer",
                    data: {
                        customerKey: this.chooseList.map(item=>item.companyKey),
                        ukey: this.kehuzhuanyi.value,
                    },
                });
                if (res.isSuccess) {
                    Message.success("操作成功");
                    this.kehuzhuanyi.visible = false;
                    this.getCustomerList();
                } else {
                    throw new Error();
                }
            } catch {
                Message.error("操作失败");
            } finally {
                this.kehuzhuanyi.loading = false;
            }
        },
        async getColleageOptions() {
            try {
                const res = await this.$axios({
                    method: "POST",
                    url: this.$authUrl + "/api/Staff",
                    data: {
                        companyKey: JSON.parse(
                            window.localStorage.getItem("userInfo")
                        ).companyKey,
                        pageIndex: 1,
                        pageSize: 50,
                    },
                });
                this.kehuzhuanyi.options = res.data.datas;
            } catch {
                null;
            }
        },

        //客户标签配置
        getCustomerTag(){
            this.$axios.get(`${this.$customerUrl}/v1/Config/Tag`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.tagOption = data;
                }
            });
        },

        //选择组织结构、员工
        onChoose(valueOne,valueTwo){
            this.organizationKey = valueOne;
            this.salekey = valueTwo;
            this.onRefresh();
        },


        // 新增部分下拉框过滤筛选功能
        getNewDropdownConfig(){
            //获取同事跟过客户下拉框
            this.$axios.get(`${this.$customerUrl}/Company/GetColleagueFollowCustomer`).then((res) => {
                const { isSuccess , data } = res;
                if (isSuccess) {
                    this.colleagueConfig = data;
                }
            });
            //获取客户国家下拉框
            this.$axios.get(`${this.$customerUrl}/Company/GetCustomerCountrys`).then((res) => {
                const { isSuccess , data } = res;
                if (isSuccess) {
                    data.forEach(item => {
                        item.flagUrl = window.getFlagUrlByCountry ? window.getFlagUrlByCountry(item.label):'';
                    });
                    this.countryConfig = data;
                }
            });
            //获取公司来源下拉框
            this.$axios.get(`${this.$customerUrl}/Company/GetCustomerSources`).then((res) => {
                const { isSuccess , data } = res;
                if (isSuccess) {
                    this.sourceConfig = data;
                }
            });
            this.followConfig = [
                {label: "7-15天未跟进",value: "0"},
                {label: "15-30天未跟进",value: "1"},
                {label: "30-90天未跟进",value: "2"},
                {label: "90天以上未跟进",value: "3"},
            ]  
            this.sortConfig = [
                {label: "最新跟进排序",value: "0"},
                {label: "星级排序",value: "1"},
                {label: "创建时间排序",value: "2"},
                {label: "评分排序",value: "3"},
            ]
            //获取公司状态下拉框
            this.$axios.get(`${this.$customerUrl}/Company/GetCustomerStatu`).then((res) => {
                const { isSuccess , data } = res;
                if (isSuccess) {
                    this.statuConfig = data;
                }
            });
            //获取客户私海分组下拉框
            this.$axios.get(`${this.$customerUrl}/Company/GetCustomerGroup`).then((res) => {
                const { isSuccess , data } = res;
                if (isSuccess) {
                    this.privateGroupConfig = data;
                }
            });
        },

    },
    mounted() {
        let lastFollow = this.$route.query.lastFollow;
        if(lastFollow){
            this.filterParam.lastFollow = lastFollow;
        }
        //获取公司系统配置
        this.comConfig = localStorage.comConfig ? JSON.parse(localStorage.comConfig) : {};
        this.comConfig.customerFieldSettings.forEach(item =>{
            this.isRequired[item.column] = item.required;
        })

        
        this.getNewDropdownConfig();    //新增部分下拉框过滤筛选功能

        this.getCustomerList();
        this.getTableColumnWhenMounted()
        this.getGroupList()
        this.getSourceOption()
        this.getStatusOption()
        this.getOptions()
        this.getCustomerTag();
        this.getColleageOptions();
        // 获取存档的筛选条件
        this.getSearchRecord();

        setTimeout(() => {
            this.$refs.customerTable.doLayout();
        }, 400);
    },
    watch:{
        // 列表更新，解决表格错乱问题
        customerList(){
            this.$nextTick(() =>{
                this.$refs.customerTable.doLayout();
            })
        }
    }
};
</script>

<style lang="scss" scoped>
.viewbox{
    width: calc(100% - 200px);
    height: calc(100vh - 140px);
    background: rgba($color: #FFFFFF, $alpha: 0.7);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    margin-bottom: 60px;
    .smallIcon{
        // 小图标样式
        vertical-align: middle;
        padding-right: 5px;
        width: 14px;
        height: 14px;
    }
    .el-button{
        height: 32px;
    }

    .boxOne,.boxTwo{
        overflow: hidden;
        padding: 8px 0;
        line-height: 32px;
        border-bottom: 1px solid #E6E7EB;
        .title{
            float: left;
            margin-left: 40px;
            font-size: 24px;
            font-weight: bold;
            color: #3A3A3A;
        }
        .txt{
            float: left;
            margin-left: 40px;
            font-size: 16px;
            color: #7C90A5;
            .el-icon-refresh{
                &:hover{
                    color: var(--themeColor);
                }
            }
        }
        .rightHandle{
            float: right;
            margin-right: 20px;
        }
    }

    .boxTable{
        background-color: #fff;
        .companyName{
            font-size: 14px;
            font-weight: bold;
            color: var(--themeColor);
            &:hover{
                text-decoration: underline;
            }
        }
    }
    
    .bottomPagination{
        position: fixed;
        bottom: 0;
        right: 0;
        z-index: 10;
        width: calc(100vw - 200px);
        height: 60px;
        background: #FFFFFF;
        box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.16);
        .txt{
            float: left;
            margin-left: 20px;
            font-size: 16px;
            line-height: 60px;
            color: #7C90A5;
        }
    }
}
.riskIcon{
    width: 24px;
    vertical-align: middle;
    margin-right: 5px;
}
/deep/.el-dialog__body {
    padding: 10px 20px;
}
/* 筛选条件弹框样式 */
.filterTitle{
    font-size: 16px;
    height: 24px;
    line-height: 24px;
    font-weight: bold;
    color: #092338;
    margin-bottom: 10px;
}
.newCustomerTtiel {
    height: 20px;
    line-height: 20px;
    font-size: 16px;
    font-weight: bold;
    color: #3A3A3A;
    margin-bottom: 10px;
    border-left: 3px solid var(--themeColor);
    padding-left: 5px;
}
.saveRecordList{
    display: flex;
    flex-wrap: wrap;
    .saveItem{
        padding-left: 20px;
        padding-right: 10px;
        height: 28px;
        line-height: 28px;
        font-size: 14px;
        color: #fafafa;
        background-color: var(--themeColor);
        border-radius: 14px;
        cursor: pointer;
        margin-right: 10px;
    }
}
</style>